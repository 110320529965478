import * as React from "react";
import '../assets/sass/app.scss';
import Header from '../components/global/Header.js';
import Footer from '../components/global/Footer.js';
import Bar from '../components/global/Bar.js'
import { Helmet } from "react-helmet";
import SEO from '../components/global/SEO.js';
import { useLocation } from '@reach/router';
import FooterCalloutBar from "../components/global/FooterCalloutBar.js";
import scrollToElement from '../components/global/ScrollToElement';

const PageNotFound = () => {
  const publicUrl = useLocation().href;
  return (
    <>
      <Helmet
        title={SEO["404-page"].title}>
        <meta
          name="description"
          content={SEO["404-page"].description}
        />
        <meta 
          property="og:image" 
          content={publicUrl + '/images/global/GileadHIVTestNow.png'}
        />
      </Helmet>
      <Header scrollToElement={scrollToElement} />
      <div className="container-fluid bkg-white">
        <div className="container">
          <div className="row">
            <div className="col">
              <Bar classes="purple" />
            </div>
          </div>
        </div>
        <div className="container page-not-found">
          <div className="row">
            <div className="col">
              <h1>
                404 Page Not Found
              </h1>
              <p>
                Try selecting a page from the menu, retyping the site address, or visiting the homepage.
              </p>
              <a href="/" className="button transparent bdr-blue thin">
                BACK TO HOME
              </a>
            </div>
          </div>
        </div>
      </div>
      <FooterCalloutBar/>
      <Footer />
    </>
  )
}

export default PageNotFound
